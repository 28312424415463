<template>
  <revenue
    :loading="loading"
    :chart-options="getChartOptions"
    :current-user-context="currentUserContext"
    :can-refetch="canRefetch"
    @refetch="refetch"
  />
</template>

<script>
import th from '@tillhub/javascript-sdk'
import Revenue from './Revenue'

export default {
  name: 'RevenuePerWeekDay',
  components: {
    Revenue
  },
  props: {
    currentUserContext: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      revenuePerWeekDay: [],
      loading: false,
      canRefetch: false
    }
  },
  computed: {
    selectedDates() {
      return this.currentUserContext.selectedDates
    },
    resourceQuery() {
      // global branch filter overrides local branch filter
      let branchNumber
      if (Number.isFinite(this.currentUserContext.currentBranchNumber)) {
        branchNumber = this.currentUserContext.currentBranchNumber
      }

      const query = {
        location: this.currentUserContext.currentLocation || undefined,
        branch_number: branchNumber,
        start: this.selectedDates.start,
        end: this.selectedDates.end
      }

      return query
    },
    getChartOptions() {
      return {
        title: this.$t('pages.home.widgets.revenue_per_week_day.title'),
        xAxis: { name: this.$t('pages.reports.statistics.overview.day') },
        series: [
          {
            name: this.$t('pages.reports.statistics.overview.turnover'),
            data: this.revenuePerWeekDay,
            type: 'bar'
          }
        ]
      }
    },
    weekdays() {
      return [
        this.$t('pages.reports.statistics.overview.sunday'),
        this.$t('pages.reports.statistics.overview.monday'),
        this.$t('pages.reports.statistics.overview.tuesday'),
        this.$t('pages.reports.statistics.overview.wednesday'),
        this.$t('pages.reports.statistics.overview.thursday'),
        this.$t('pages.reports.statistics.overview.friday'),
        this.$t('pages.reports.statistics.overview.saturday')
      ]
    }
  },
  watch: {
    selectedDates(value) {
      this.updateRevenuePerWeekDay()
    }
  },
  mounted() {
    this.updateRevenuePerWeekDay()
    this.$emitter.on('refresh-requested', () => {
      this.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    async updateRevenuePerWeekDay() {
      if (!this.selectedDates.start || !this.selectedDates.end) {
        this.revenuePerWeekDay = undefined
        return
      }

      this.loading = true
      try {
        const resp = await th
          .analytics({ timeout: 90000 })
          .getRevenuesForDayOfWeek(this.resourceQuery)
        const values = resp.data[0].values
        // Send the sunday to the end of the array
        values.push(values.shift())

        this.revenuePerWeekDay = values.map((point) => [
          this.weekdays[point[0]],
          point[1]
        ])
      } catch (e) {
        this.canRefetch = true
        this.revenuePerWeekDay = []
      } finally {
        this.loading = false
      }
    },
    refresh() {
      this.$nextTick(() => {
        this.refetch()
      })
    },
    refetch() {
      this.updateRevenuePerWeekDay()
      this.canRefetch = false
    }
  }
}
</script>
